@import "../../../colors/index.scss";

.template-form{
  .ui-form{
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 32px;
    .MuiTextField-root {
      width: 100%;
      &.textarea{
        input{
          height: 54px;
        }
      }
    }
    .MuiFormControl-root {
      margin-bottom: 16px;
      font-size: 16px;
      width: 100%;
    }
    .MuiButton-root{
      cursor: pointer;
      margin-top: 16px;
    }
    .MuiOutlinedInput-root{
      .MuiSelect-select{
        display: flex;
      }
      .MuiListItemIcon-root{
        min-width: 32px;
      }
    }
  }
}