@import "../../../colors/index.scss";

.submission-form{
  .ui-form{
    margin: auto;
    display: flex;
    height: 100%;
    width: 70%;
    justify-content: center;
    align-items: center;
    border: 1px solid $medium-gray;
    flex-direction: column;
    padding: 32px 10% 32px 10%;
    .content-wrapper{
      width: fit-content;
      margin: auto;
      >div:not(:last-child) {
        margin-bottom: 20px;
      }
    }
    .ace-editor{
      max-width: 400px;
    }
    .ui-form-label {
      &.row{
        width: 100%;
        text-align: left;
      }
    }
    .form-label {
      width: 100%;
      text-align: left;
      margin-bottom: 20px;
      img{
        width: 217px;
        border: 1px solid $medium-gray;
      }
    }
    .MuiTextField-root {
      margin-bottom: 16px;
      width: 100%;
      input {
        height: 18px;
        font-size: 14px;
      }
      .MuiInputLabel-root {
        font-size: 14px;
      }
      &.textarea{
        input{
          height: 54px;
        }
      }
    }
    .MuiFormControl-root {
      margin-bottom: 16px;
      font-size: 14px;
      width: 100%;
    }
    .buttons-wrapper{
      width: 100%;
      display: flex;
      justify-content: space-between;
      .MuiButton-root{
        cursor: pointer;
        margin-top: 16px;
        &.approve{
          border-color: $green;
          color: $green;
        }
        &.reject{
          margin-right: 5px;
          border-color: $red;
          color: $red;
        }
      }
    }
  }
}