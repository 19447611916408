.pages-templates{
  .cursor-pointer {
    cursor: pointer;
  }
  .buttons-wrapper{
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    label{
      cursor: pointer;
    }
  }
  .icon-wrapper{
    margin-bottom: 10px;
    div{
      cursor: pointer;
      svg{
        width: 36px;
        height: 36px;
      }
    }
  }
  .ui-table{
    .icons-wrapper {
      width: 100%;
      div{
        text-align: center;
        cursor: pointer;
      }
    }
  }
  .MuiSvgIcon-root{
    margin-right: 8px;
  }
}